<template>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="
          d-flex
          align-items-center
          justify-content-center justify-content-sm-start
        "
      >
        <span class="text-muted">{{
          $t("table.foot", { from: from, to: to, total: total })
        }}</span>
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="
          d-flex
          align-items-center
          justify-content-center justify-content-sm-end
        "
      >
        <b-pagination
          v-model="localValue"
          :total-rows="total"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BPagination, BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'TableFooter',
  components: {
    BPagination,
    BCol,
    BRow,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    from: {
      type: Number,
      required: true,
    },
    to: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    }
  },
  watch: {
    localValue(newValue) {
      this.$emit('input', newValue)
    },
    value(newValue) {
      this.localValue = newValue
    },
  },
}
</script>

<style>
</style>
