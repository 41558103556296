<template>
  <div>
    <b-row>
      <b-col cols="10">
        <h1>{{ $t("Códigos promocionales") }}</h1>
        <h5 class="text-primary">
          {{ $t("codPromPlatform") }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-tabs
          lazy
          pills
        >
          <b-tab active>
            <template #title>
              <span>{{ $t("codes") }}</span>
            </template>
            <promotional-codes />
          </b-tab>
          <b-tab>
            <template #title>
              <span>{{ $t("Campañas") }}</span>
            </template>
            <campaign-promotional-code />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import promotionalCodes from './promotionalCodes.vue'
import campaignPromotionalCode from './campaignPromotionalCode.vue'

export default {
  components: {
    BRow,
    BCol,
    promotionalCodes,
    campaignPromotionalCode,
    BTab,
    BTabs,

  },

}
</script>
