<template>
  <div class="m-2">
    <!-- Table Top -->
    <b-row>
      <!-- Per Page -->
      <b-col
        md="8"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label v-if="showEntries">{{ $t('table.Show') }}</label>
        <v-select
          v-if="showEntries"
          v-model="internalPerPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label v-if="showEntries">{{ $t('table.entries') }}</label>
      </b-col>

      <!-- Search -->
      <b-col
        md="4"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="internalSearchQuery"
            class="d-inline-block mr-1"
            :placeholder="$t('Search')"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormInput, BCol, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormInput,
    BCol,
    BRow,
    vSelect,
  },
  props: {
    perPage: {
      type: Number,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    showEntries: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:perPage', 'update:searchQuery'],
  data() {
    return {
      internalPerPage: 10,
      internalSearchQuery: '',
      perPageOptions: [10, 25, 50, 100],
    }
  },
  watch: {
    internalPerPage(newValue) {
      this.$emit('update:perPage', newValue)
    },
    internalSearchQuery(newValue) {
      this.$emit('update:searchQuery', newValue)
    },
    perPage(newValue) {
      this.internalPerPage = newValue
    },
    searchQuery(newValue) {
      this.internalSearchQuery = newValue
    },
  },
}
</script>

<style>

</style>
