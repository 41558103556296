import axios from '@axios'
import generateInputs from '@/libs/utils/generate-inputs'
import { getUserData } from '@/auth/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            // params: queryParams
            query: `
              {
                allVusers(
                    first: ${queryParams.perPage},
                    offset: ${queryParams.perPage * (queryParams.page - 1)},
                    username: "${queryParams.q}",
                    client:"${userData.profile.client.id}",

                    orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                    ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                  ) {
                  totalCount
                  edgeCount
                  edges {
                    node {
                      id
                      username
                      firstName
                      lastName
                      email
                      isActive
                      isLegalWarning
                      isAcceptAds
                    }
                  }
                }
              }
            `,
          })
          .then(response => {
            const allVusers = response
            const searchName = response.data.data.allVusers.edges

            axios
              .post('', {
                // params: queryParams
                query: `
                              {
                                allVusers(
                                    first: ${queryParams.perPage},
                                    offset: ${queryParams.perPage * (queryParams.page - 1)},
                                    email: "${queryParams.q}",
                   client:"${userData.profile.client.id}",

                                    orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                                    ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                                  ) {
                                  totalCount
                                  edgeCount
                                  edges {
                                    node {
                                      id
                                      username
                                      firstName
                                      lastName
                                      email
                                      isActive
                                      isLegalWarning
                                      isAcceptAds
                                    }
                                  }
                                }
                              }
                          `,
              }).then(result => {
                const searchMail = result.data.data.allVusers.edges
                let nuevo = [...searchName, ...searchMail]

                const hash = {}
                nuevo = nuevo.filter(current => {
                  const exists = !hash[current.node.id]
                  hash[current.node.id] = true
                  return exists
                })
                allVusers.data.data.allVusers.edges = nuevo
                resolve(allVusers)
              }).catch(err => {
                reject(err)
              })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchUser(ctx, { id }) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              {
                __type(name: "VuserNode") {
                  fields {
                    name
                    description
                    type {
                      kind
                      ofType {
                        name
                      }
                      name
                      description
                      enumValues {
                        description
                        name
                      }
                    }
                  }
                }
                allVusers(id: "${id}",
                client:"${userData.profile.client.id}"
                ) {
                  
                    edges {
                      node {
                        id          
                        username
                        firstName
                        lastName
                        email
                        isActive
                        dateJoined
                        lastLogin
                        gender
                        phone
                        address
                        city
                        province
                        postalCode
                        country
                        nif
                        pin
                        description
                        confirmedEmail
                        isLegalWarning
                        isAcceptAds
                        subgroup{
                          id
                          name
                        }
                        userAddress {
                          edges {
                            node {
                              id
                              name
                              lastName
                              addressName
                              type
                              province
                              city
                              address
                              postalCode
                              country {
                                id
                              }
                              nif
                              phone
                              isActive
                            }
                          }
                        }
                      }
                    }
                  }
              }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    mutateUser(ctx, data) {
      const { user } = data
      const { fields } = data
      let { parsedInputs, vars } = generateInputs(user, fields, ['dateJoined', 'lastLogin'])
      return new Promise((resolve, reject) => {
        if (parsedInputs.includes('subgroup:null')) {
          parsedInputs = parsedInputs.replace(/subgroup\s*:\s*null/, 'subgroup:$subgroup')
          vars += '$subgroup: ID'
        }
        axios
          .post('', {
            query: `
              mutation mutateVUser${vars ? '(' : ''}${vars}${vars ? ')' : ''}{
                updateVuser(id:"${user.id}", input: ${parsedInputs}) {
                  vUser{
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    dateJoined
                    lastLogin
                    gender
                    phone
                    address
                    city
                    province
                    postalCode
                    country
                    nif
                    pin
                    description
                    confirmedEmail
                    isLegalWarning
                    isAcceptAds
                    subgroup{
                      id
                      name
                    }
                  }
                }
              }
            `,
            variables: {
              subgroup: null,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
              mutation{
                deleteVuser(id:"${id}") {
                  found
                  deletedId
                }
              }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
