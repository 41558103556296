export default (user, introspectionUser, ignoreFields) => {
  const inputs = { ...user }
  delete inputs.id
  ignoreFields.forEach(element => {
    delete inputs[element]
  })
  // Only send changed fields
  Object.keys(inputs).map(key => {
    if (inputs[key] === introspectionUser[key].value) {
      delete inputs[key]
    }
    return key
  })
  const json = JSON.stringify(inputs)
  let parsedInputs = json.replace(/"([^"]+)":/g, '$1:')
  let vars = ''
  Object.keys(inputs).map(key => {
    if (introspectionUser[key].type.kind === 'ENUM' && introspectionUser[key].type.enumValues) {
      introspectionUser[key].type.enumValues.map(item => {
        parsedInputs = parsedInputs.replace(`"${item.name}"`, `${item.name}`)
        return item
      })
    }
    if (introspectionUser[key].type.name === 'Int') {
      parsedInputs = parsedInputs.replace(`${key}:"${user[key]}"`, `${key}:${user[key]}`)
      if (user[key] === null || user[key] === '') {
        parsedInputs = parsedInputs.replace(`${key}:`, `${key}:$nullInt`)
      }
    }
    return key
  })
  if (parsedInputs.indexOf('$nullInt') !== -1) {
    vars = vars.concat('$nullInt:Int ')
  }
  return { parsedInputs, vars }
}
