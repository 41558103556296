import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFinalUserList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'username', sortable: true, label: i18n.t('administrators.user') },
    { key: 'email', sortable: true },
    { key: 'isActive', sortable: true, label: i18n.t('code.status') },
    { key: 'actions', label: i18n.t('resources.tableHeader.actions') },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('final-user-list/fetchUsers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { edges, totalCount } = response.data.data.allVusers

        callback(edges)
        totalUsers.value = totalCount
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('errorList'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteUser = id => {
    store
      .dispatch('final-user/deleteUser', {
        id,
      })
      .then(response => {
        const { found, deletedId } = response.data.data.deleteVuser
        if (found && deletedId) {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              icon: 'success',
              title: i18n.t('deleteSuccess'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = status => {
    if (status === true) return 'success'
    if (status === false) return 'secondary'
    return 'secondary'
  }

  return {
    fetchUsers,
    deleteUser,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,

    resolveUserStatusVariant,

    // Extra Filters
    statusFilter,
  }
}
