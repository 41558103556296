<template>
  <section id="subscriptionSelector">
    <b-row class="mb-3">
      <b-col
        style="text-align-last: start;"
        class="ml-1"
      >
        <h1>{{ $t('Subscriptions') }}</h1>
        <h5 class="text-primary">
          {{ $t('domain.select') }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="11">
        <b-form-input
          v-model="buscar"
          :placeholder="$t('Search')"
          @change="busqueda"
        />
      </b-col>
      <b-col>
        <b-button
          variant="primary"
          @click="busqueda"
        >
          {{ $t('Search') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          selectable
          select-mode="range"
          fixed
          responsive
          hover
          :sticky-header="true"
          no-border-collapse
          :items="response"
          :fields="tableColumns"
          @row-selected="onRowSelected"
        >
          <template #cell(Nombre)="data">
            <b-row class="justify-content-center">
              <p>{{ data.item.node.name }}</p>
            </b-row>
          </template>
          <template #cell(Precio)="data">
            <b-row class="justify-content-center">
              <p>
                {{
                  data.item.node.price == 0 ? $t('dataGeneric.internal') : data.item.node.price
                }}

                {{ data.item.node.price == 0 ? "" : currencyType[currency] }}
              </p>
            </b-row>
          </template>
          <template #cell(Intervalo)="data">
            <b-row class="justify-content-center">
              <p>{{ intervalName[data.item.node.interval] }}</p>
            </b-row>
          </template>
          <template #cell(País)="data">
            <b-row class="justify-content-center">
              <p>
                {{
                  data.item.node.country == null
                    ? ""
                    : data.item.node.country.country
                }}
              </p>
            </b-row>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
          @input="allSubscription"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BPagination,
  BCol,
  BRow,
  BButton,
  BTable,
  BFormInput,
} from 'bootstrap-vue'
import axios from '@axios'
import { showToast, messageError } from '@/store/functions'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BTable,
    BPagination,
    BCol,
    BRow,
    BButton,
    BFormInput,
  },
  props: {
    code: {
      type: String,
      default: '',
    },
    isStripe: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      selected: null,
      response: null,
      vacio: false,
      currency: null,
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
      },
      currentParentFolder: null,
      perPage: 15,
      currentPage: 1,
      rows: null,
      buscar: '',
      tableColumns: [
        // { key: 'id', sortable: true },
        { key: this.$t('dataGeneric.name'), sortable: false },
        { key: this.$t('purchases.price'), sortable: false },
        { key: this.$t('common.Intervalo'), sortable: false },
        { key: this.$t('finalUsers.Country'), sortable: false },
      ],
      intervalName: {
        DAY: this.$t('code.Daily'),
        WEEK: this.$t('code.Weekly'),
        MONTH: this.$t('code.Monthly'),
        YEAR: this.$t('code.Annual'),
        LIFETIME: this.$t('code.Vitalicia'),
      },
    }
  },
  mounted() {
    this.getPayMethod()
    this.allSubscription()
  },
  methods: {

    onRowSelected(data) {
      if (data[0]) {
        showToast(data[0].node.name + this.$t('common.sl'), 1, this)
        this.selected = [data[0].node.id, data[0].node.name]
        if (this.selected == null) {
          showToast(this.$t('common.selSUS'), 2, this)
        } else {
          this.$emit('data', this.selected)
        }
      }
    },
    allSubscription() {
      const userData = getUserData()
      axios
        .post('', {
          query: `
            query{  
            allSubscription(
                  first:${this.perPage},
                  ${this.isStripe != null ? `isStripeGestioned:${this.isStripe}` : ''}
                  offset: ${this.perPage * (this.currentPage - 1)}, client: "${userData.profile.client.id}",
          name: "${this.buscar}",
        ){
                pageInfo{
          hasPreviousPage
          hasNextPage
        }
        totalCount
        edgeCount
              edges {
                node {
            id
            name
            price
            isStripeGestioned
                  country{
              country
            }
            interval

          }
        }
      }
    }
        `,
        })
        .then(res => {
          messageError(res, this)
          this.response = res.data.data.allSubscription.edges
          this.vacio = this.response.length === 0
          this.rows = res.data.data.allSubscription.totalCount
        }).catch(() => {
        })
    },
    getPayMethod() {
      const userData = getUserData()
      const { id } = userData.profile.client
      axios
        .post('', {
          query: `
              query{
  client(id: "${id}"){
    externalPayMethod
    currency
  }
}
`,
        })
        .then(res => {
          messageError(res, this)
          this.currency = res.data.data.client.currency
        })
        .catch(() => {
          this.currency = null
        })
    },
    busqueda() {
      this.allSubscription()
    },

  },
}
</script>

<style>
#subscriptionSelector #card {
  background-color: aliceblue;
}

#subscriptionSelector .td {
  width: 50px;
  height: 100px;
}

#subscriptionSelector .folder {
  min-height: 150px;
  height: 300px;
  cursor: pointer;
}

#subscriptionSelector .dropContainer {
  border: 3px dashed;
}

#subscriptionSelector .card-width {
  width: 200px;
}

#subscriptionSelector .list-group-item {
  transition: all 1s;
}
</style>
